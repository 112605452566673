'use client';

import Link from 'next/link';
import classes from './error.module.scss';

const ErrorPage = () => {
  return (
    <div className={classes.container}>
      <svg className={classes.icon} viewBox="0 0 191 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 4515">
          <path
            id="Vector 165"
            d="M95.0007 158C95.0007 115.5 131.001 25.5 73.5097 8.81166C42.5107 -0.186531 19.2365 33.0843 29.5074 60.5514"
            stroke="#FF5C32"
            stroke-width="12"
          />
          <path
            id="Vector 166"
            d="M131 158C131 124.5 118.443 74.0389 147.095 68.4826C166.101 64.7968 179.658 82.915 173.496 99.2468"
            stroke="#FF5C32"
            stroke-width="12"
          />
          <path
            id="Vector 167"
            d="M6.999 87.5003C7.14805 71.3963 17.0091 59.8535 27.0091 56.0431C47.5091 51.0432 58.9816 68.038 60.5 73.5L40.5632 76.6221L36.6262 94.072L26.4848 80.0934L6.999 87.5003Z"
            stroke="#FF5C32"
            stroke-width="12"
          />
          <path
            id="Vector 168"
            d="M163.455 113.685C163.54 104.537 163.455 94.4898 173.324 92.0829C186.955 88.7584 189.093 102.642 189.955 105.744L181.023 103.773L178.787 113.685L173.026 105.744L163.455 113.685Z"
            fill="#FF5C32"
          />
        </g>
      </svg>
      <p className={classes.text}>Щось пішло не так, перезавантажте сторінку або перейдіть на головну</p>
      <Link className={classes.link} href="/">
        Перейти на головну сторінку
      </Link>
    </div>
  );
};

export { ErrorPage };
