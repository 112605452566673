'use client';

import { useEffect } from 'react';

import { postErrorMessage } from '@api/logging';
import { PRODUCTION_ENV } from '@lib/constants/environment';
import { ErrorPage } from '@page-layouts/error';

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    if (error.message || error.stack) {
      postErrorMessage({
        message: `${!PRODUCTION_ENV ? '[STAGING] ' : ''}` + `{Error Page} ${error.message}|${error.cause}`,
        data: {
          timestamp: new Date().toISOString(),
          path: window.location.toString(),
          stack: error.stack,
          digest: error.digest
        }
      }).catch(console.error);
    }
  }, [error]);

  return <ErrorPage />;
}
